<template>
  <div>
    <div class="config-container">
      <p>配置转换器：城邦</p><br/><br/>
      <div class="config-item">
        <input type="file" id="config" name="config" onclick="this.value=null;"
               @change="convertConfig">
      </div>
      <div class="config-item">
        <a :href="publicPath + 'config/city.xlsx'"
           download="0510-13.xlsx">下载配置模版(0510)</a>
      </div>
    </div>
    <hr>

    <div style="display: flex; align-items: center;">
      <div>
        <span id="target-army" title="面向方向" style="display: none;">
          ↑<br>我<br>军<br>面<br>向<br></span>
        <span id="target-enemy" title="面向方向">
          敌<br>军<br>面<br>向<br>↓<br></span>
      </div>
      <div class="board-container">
        <div class="board-item"
             v-for="(data, index) in boardList"
             :key="index"
             @click="boardClick(data)">
          {{data.name}}
          <br>
          {{data.number}}
        </div>
      </div>
      <div><button type="button" @click="resetBoardBelong">切换阵营</button></div>
      <div><button type="button"><a href="#config-modal">更新配置</a></button></div>
      <div><button type="button" @click="exportBoard">导出阵型代码</button></div>
      <div><button type="button" @click="() => {this.isExport = false;}">
        <a href="#board-json-modal">导入阵型代码</a>
      </button></div>
      <div><button type="button" @click="clearBoard">清空当前阵型</button></div>
    </div>

    <div id="config-modal" class="overlay">
      <a href="#" class="cancel">Cancel</a>
      <div class="modal">
        <h3>更新配置</h3>
        <div style="display: flex">
          <div>请上传“配置 Excel”导出的 ".json" 文件：</div>
          <div><input type="file" id="army-config-file" name="config"></div>
        </div>
        <button type="button" @click="upgradeAllArmy"><a href="#">更 新</a></button>

        <a href="#" class="modal-close">×</a>
      </div>
    </div>
    <div id="army-modal" class="overlay">
      <a href="#" class="cancel">Cancel</a>
      <div class="modal">
        <h3>输入数量</h3>
        <div class="demo">
          <input type="number" v-model="armyNumber" :max="armyMax">
        </div>
        <h3>选择兵种</h3>
        <div class="demo">
          <select v-model="armySelected" @focusout="choiceArmy">
            <option value="-1">-</option>
            <option v-for="(data, id) in CONFIG[boardBelong]" :key="id" :value="id">
              {{ data.title }}
            </option>
          </select>
        </div>

        <a href="#" class="modal-close">×</a>
      </div>
    </div>
    <div id="board-json-modal" class="overlay">
      <a href="#" class="cancel">Cancel</a>
      <div class="modal" v-if="isExport">
        <h3>请复制：</h3>
        <p id="board-json-text">{{this.boardListJSON}}</p>
        <a href="#" class="modal-close">×</a>
      </div>
      <div class="modal" v-else>
        <h3>请放入阵型代码：</h3>
        <textarea name="importBoardList"
                  rows="10"
                  style="width: 70%"
                  v-model="boardListJSON"></textarea>
        <div><button type="button" @click="importBoard">
          <a href="#">导 入</a>
        </button></div>
        <a href="#" class="modal-close">×</a>
      </div>
    </div>
  </div>
</template>

<script>
const ExcelJS = require('exceljs');

export default {
  name: 'City',
  data: () => ({
    BOARD_UNIT: 25,
    CONFIG: null,
    boardBelong: 'enemy',
    boardList: [],
    boardListJSON: '',
    publicPath: process.env.BASE_URL,
    isExport: false,
    // 双击事件
    delayBoard: 750,
    clicksBoard: 0,
    timerBoard: null,
    armySelected: '',
    armyNumber: 0,
    armyMax: 30,
    boardChoice: -1,
  }),
  created() {
    if (localStorage.getItem('boardCache')) {
      this.boardList = JSON.parse(localStorage.getItem('boardCache'));
    } else {
      for (let i = 0; i < this.BOARD_UNIT; i++) {
        this.boardList[i] = {
          index: i,
          id: -1,
          number: 0,
          name: '-',
        };
      }
    }

    if (localStorage.getItem('boardConfig')) {
      this.CONFIG = JSON.parse(localStorage.getItem('boardConfig'));
    } else {
      this.CONFIG = {
        army: [],
        enemy: [],
      };
      this.$tip.fire({
        icon: 'error',
        title: '缺乏士兵配置，如需使用模拟棋盘，请先使用“更新配置”按钮',
      });
    }

    this.showBoard();
  },
  methods: {
    // 选择兵种
    choiceArmy() {
      if (this.armySelected === '-1') {
        this.$set(this.boardList, this.boardChoice, {
          index: this.boardChoice,
          id: -1,
          name: '-',
          number: 0,
        });
      } else {
        if (this.armyNumber > this.armyMax) {
          this.armyNumber = this.armyMax;
        }
        this.$set(this.boardList, this.boardChoice, {
          index: this.boardChoice,
          id: this.armySelected,
          name: this.CONFIG[this.boardBelong][this.armySelected].title,
          number: this.armyNumber,
        });
      }
      localStorage.setItem('boardCache', JSON.stringify(this.boardList));
    },
    // 点击棋盘
    boardClick(boardItem) {
      this.clicksBoard++;
      if (this.clicksBoard === 1) {
        this.timerBoard = setTimeout(() => {
          this.clicksBoard = 0;
        }, this.delayBoard);
      } else {
        clearTimeout(this.timerBoard);
        this.boardChoice = boardItem.index;
        this.armySelected = this.boardList[this.boardChoice].id ?? 0;
        this.armyNumber = this.boardList[this.boardChoice].number ?? 0;
        window.location.href = '#army-modal';
        this.clicksBoard = 0;
      }
    },
    // 刷新棋盘
    showBoard() {
      let i = 0;
      while (i < this.BOARD_UNIT) {
        if (this.boardList[i].id === -1
          || this.CONFIG[this.boardBelong].length < this.boardList[i].id + 1) {
          this.$set(this.boardList, i, {
            index: i,
            id: -1,
            number: 0,
            name: '-',
          });
        } else {
          this.$set(this.boardList, i, {
            index: i,
            id: this.boardList[i].id,
            number: this.boardList[i].number,
            name: this.CONFIG[this.boardBelong][this.boardList[i].id].title,
          });
        }
        i++;
      }
    },
    // 清理阵型
    clearBoard() {
      for (let i = 0; i < this.BOARD_UNIT; i++) {
        this.boardList[i] = {
          index: i,
          id: -1,
          number: 0,
          name: '-',
        };
      }
      this.showBoard();
    },
    // 导入阵型代码
    importBoard() {
      if (this.boardListJSON === '') {
        this.$tip.fire({
          icon: 'error',
          title: '无内容，无法转换为阵型',
        });
        return;
      }
      try {
        const json = JSON.parse(this.boardListJSON);
        if (!Array.isArray(json)) {
          this.$tip.fire({
            icon: 'error',
            title: '阵型代码格式有误',
          });
          return;
        }
        for (let i = this.boardList.length - 1; i >= 0; i--) {
          if (json[i].id === -1) {
            this.$set(this.boardList, i, {
              index: i,
              id: json[i].id,
              number: 0,
              name: '-',
            });
          } else {
            this.$set(this.boardList, i, {
              index: i,
              id: json[i].id,
              number: json[i].number ?? this.armyMax,
              name: this.CONFIG[this.boardBelong][json[i].id].title,
            });
          }
        }
      } catch (e) {
        this.$tip.fire({
          icon: 'error',
          title: `JSON 格式有误: ${e}`,
        });
      }
    },
    // 导出阵型代码
    exportBoard() {
      this.isExport = true;
      const newBoardList = JSON.parse(JSON.stringify(this.boardList));
      for (let i = 0; i < newBoardList.length; i++) {
        delete newBoardList[i].name;
      }
      this.boardListJSON = JSON.stringify(newBoardList);
      window.location.href = '#board-json-modal';

      setTimeout(() => {
        try {
          const range = document.createRange();
          range.selectNodeContents(document.getElementById('board-json-text'));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
          document.execCommand('copy');
          window.getSelection().removeAllRanges();
          this.$tip.fire({
            position: 'top-end',
            icon: 'success',
            title: '已复制到剪切板',
            showConfirmButton: false,
            timer: 1000,
          });
        } catch (e) {
          console.log(e);
        }
      }, 80);
    },
    // 切换兵种
    resetBoardBelong() {
      if (this.boardBelong === 'army') {
        document.getElementById(`target-${this.boardBelong}`).style.display = 'none';
        this.boardBelong = 'enemy';
        document.getElementById(`target-${this.boardBelong}`).style.display = '';
      } else {
        document.getElementById(`target-${this.boardBelong}`).style.display = 'none';
        this.boardBelong = 'army';
        document.getElementById(`target-${this.boardBelong}`).style.display = '';
      }
      this.showBoard();
    },
    // 更新双方军队配置
    upgradeAllArmy() {
      const file = document.getElementById('army-config-file').files[0];
      if (file.name.search(/.json$/) < 0) {
        this.$tip.fire({
          icon: 'error',
          title: `不支持的文件格式: ${file.name}`,
        });
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (evt) => {
        const config = JSON.parse(evt.target.result);
        if (config.army === undefined) {
          this.$tip.fire({
            icon: 'error',
            title: '更新失败，配置文件中不存在 Key: army',
          });
          return;
        }
        if (config.enemy === undefined) {
          this.$tip.fire({
            icon: 'error',
            title: '更新失败，配置文件中不存在 Key: ',
          });
          return;
        }
        localStorage.setItem('boardConfig', JSON.stringify({
          army: config.army,
          enemy: config.enemy,
        }));
        this.CONFIG = JSON.parse(localStorage.getItem('boardConfig'));
        this.showBoard();
      };
      fileReader.readAsText(file);
      return true;
    },

    // Excel 转 JSON
    convertConfig(event) {
      const file = event.target.files[0];
      if (file.name.search(/.xlsx$/) < 0 && file.name.search(/.xls$/) < 0) {
        this.$tip.fire({
          icon: 'error',
          title: `不支持的文件格式: ${file.name}`,
        });
        return false;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(arrayBuffer).then((excel) => {
          let result = '{';

          // 常量转化
          const configSheet = excel.worksheets[5];
          result += this.keyToValue(configSheet, 'Const', 1);
          result += ',';

          // enemy 转化
          const enemySheet = excel.worksheets[0];
          enemySheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"enemy": [';
              return;
            }

            let i = 1;
            result += `{
"index": "${this.getText(row.values[i++])}",
"title": "${this.getText(row.values[i++])}",
"path": "${this.getText(row.values[i++])}",
"hp": ${this.getText(row.values[i++])},
"power": ${this.getText(row.values[i++])},
"speed": ${this.getText(row.values[i++])},
"evasion": ${this.getText(row.values[i++])},
"precise": ${this.getText(row.values[i++])},
"attack": ${this.getText(row.values[i++])},
"defence": ${this.getText(row.values[i++])},
"range": ${this.getText(row.values[i++])}
}`;
            if (enemySheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += '],';
          });

          // army 转化
          const armySheet = excel.worksheets[1];
          armySheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"army": [';
              return;
            }

            let i = 1;
            result += `{
"index": ${this.getText(row.values[i++])},
"sequence": ${this.getText(row.values[i++])},
"title": "${this.getText(row.values[i++])}",
"description": "${this.getText(row.values[i++])}",
"path": "${this.getText(row.values[i++])}",
"hp": ${this.getText(row.values[i++])},
"power": ${this.getText(row.values[i++])},
"speed": ${this.getText(row.values[i++])},
"evasion": ${this.getText(row.values[i++])},
"precise": ${this.getText(row.values[i++])},
"attack": ${this.getText(row.values[i++])},
"defence": ${this.getText(row.values[i++])},
"range": ${this.getText(row.values[i++])},
"moneyConsume": ${this.getText(row.values[i++])},
"breadConsume": ${this.getText(row.values[i++])},
"iCost": {
  "money": ${this.getText(row.values[i++])},
  "bread": ${this.getText(row.values[i++])},
  "wood": ${this.getText(row.values[i++])},
  "stone": ${this.getText(row.values[i++])},
  "skin": ${this.getText(row.values[i++])},
  "ore": ${this.getText(row.values[i++])},
  "iron": ${this.getText(row.values[i++])},
  "steel": ${this.getText(row.values[i++])},
  "porcelain": ${this.getText(row.values[i++])},
  "point": ${this.getText(row.values[i++])}
  }
}`;
            if (armySheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += '],';
          });

          // building 转化
          const buildingSheet = excel.worksheets[2];
          buildingSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"building": [';
              return;
            }

            let i = 1;
            result += `{
"index": ${this.getText(row.values[i++])},
"category": ${this.getText(row.values[i++])},
"sequence": ${this.getText(row.values[i++])},
"title": "${this.getText(row.values[i++])}",
"description": "${this.getText(row.values[i++])}",
"path": "${this.getText(row.values[i++])}",
"iCost": {
  "money": ${this.getText(row.values[i++])},
  "bread": ${this.getText(row.values[i++])},
  "wood": ${this.getText(row.values[i++])},
  "stone": ${this.getText(row.values[i++])},
  "skin": ${this.getText(row.values[i++])},
  "ore": ${this.getText(row.values[i++])},
  "iron": ${this.getText(row.values[i++])},
  "steel": ${this.getText(row.values[i++])},
  "porcelain": ${this.getText(row.values[i++])},
  "point": ${this.getText(row.values[i++])}
},
"effect": [
  ${this.getText(row.values[i++])}
],
"areaOccupy": ${this.getText(row.values[i++])},
"humanOccupy": ${this.getText(row.values[i++])},
"moneyConsume": ${this.getText(row.values[i++])},
"limit": ${this.getText(row.values[i++])}
}`;
            if (buildingSheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += '],';
          });

          // hero 转化
          const heroSheet = excel.worksheets[4];
          heroSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"hero": [';
              return;
            }

            let i = 1;
            result += `{
"index": ${this.getText(row.values[i++])},
"path": "${this.getText(row.values[i++])}",
"title": "${this.getText(row.values[i++])}",
"description": "${this.getText(row.values[i++])}",
"quality": ${this.getText(row.values[i++])},
"attack": ${this.getText(row.values[i++])},
"attackIncrease": ${this.getText(row.values[i++])},
"defence": ${this.getText(row.values[i++])},
"defenceIncrease": ${this.getText(row.values[i++])},
"hp": ${this.getText(row.values[i++])},
"hpIncrease": ${this.getText(row.values[i++])},
"precise": ${this.getText(row.values[i++])},
"preciseIncrease": ${this.getText(row.values[i++])},
"evasion": ${this.getText(row.values[i++])},
"evasionIncrease": ${this.getText(row.values[i++])}`;
            if (heroSheet.getRow(rowNumber + 1).values.length) result += '}, ';
            else result += '}],';
          });

          // adventure 转化
          const adventureSheet = excel.worksheets[3];
          adventureSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result += '\n"adventure": [';
              return;
            }
            result += `{
"index": ${this.getText(row.values[1])},
"distance": ${this.getText(row.values[2])},
"title": "${this.getText(row.values[3])}",
"description": "${this.getText(row.values[4])}",
"path": "${this.getText(row.values[5])}",
"gifts": [{
    "key": "${this.getText(row.values[6])}",
    "iconPath": "${this.getText(row.values[7])}",
    "minNumber": ${this.getText(row.values[8])},
    "maxNumber": ${this.getText(row.values[9])}
}, {
    "key": "${this.getText(row.values[10])}",
    "iconPath": "${this.getText(row.values[11])}",
    "minNumber": ${this.getText(row.values[12])},
    "maxNumber": ${this.getText(row.values[13])}
}, {
    "key": "${this.getText(row.values[14])}",
    "iconPath": "${this.getText(row.values[15])}",
    "minNumber": ${this.getText(row.values[16])},
    "maxNumber": ${this.getText(row.values[17])}
}],
"armyList": ${this.getText(row.values[18]).replaceAll('\\n', '')}
}`;
            if (adventureSheet.getRow(rowNumber + 1).values.length) result += ', ';
            else result += ']';
          });
          result += '\n}';

          console.info(result);
          result = JSON.parse(result);
          result.building.map((data) => {
            data.description = data.description.replaceAll(/\[([0-9])\]/g, (i, index) => data.effect[index]);
            return data;
          });
          result = JSON.stringify(result);

          // 创建隐藏的可下载链接
          const eleLink = document.createElement('a');
          eleLink.download = 'config.json';
          eleLink.style.display = 'none';
          // 字符内容转变成blob地址
          const blob = new Blob([result]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击并移除
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);

          document.getElementById('config').value = '';
        });
      };
      reader.readAsArrayBuffer(file);
      return true;
    },
    // 获取实际 Text
    getText(cell) {
      if (cell === undefined) {
        return '';
      }
      let text = '';
      if (cell.result) {
        return cell.result;
      }
      if (cell.richText) {
        cell.richText.map((value) => {
          if (value.text === '\n' || value.text === '\r' || value.text === '\r\n') {
            text += '\\n';
          } else {
            text += value.text.replaceAll('\n', '\\n');
          }
          return value;
        });
        return text;
      }
      if (typeof cell === 'string') {
        return cell.replaceAll('\n', '\\n');
      }
      return cell;
    },

    // excel k:v 转化器
    keyToValue(configSheet, parentName, indexFix) {
      let result = '';
      configSheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          result += `\n"${parentName}": {`;
        }

        let i = 1;
        result += `"${this.getText(row.values[i++])}": "${this.getText(row.values[i + indexFix])}"`;
        if (configSheet.getRow(rowNumber + 1).values.length) result += ', ';
        else result += '}';
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-container {
  display: flex;
  justify-content: flex-start;
}
.config-item {
  flex: 0 1 20%;
  a {
    color: cornflowerblue;
    text-decoration: underline;
  }
  a:hover {
    color: cornflowerblue;
    text-decoration: none;
  }
}

.board-container {
  width: 470px;
  height: 470px; /* height given for illustration */
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.board-item {
  text-align: center;
  font-size: 0.8rem;
  border: 1px solid black;
  margin: 4px;
  flex: 0 1 calc(20% - 10px);
}

a {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}

/* 模态框 */
.overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .7);
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: relative;
    width: 600px;
    max-width: 80%;
    background: white;
    border-radius: 8px;
    padding: 1em 2em;
    .modal-close {
      position: absolute;
      top: 10px;
      right: 15px;
      color: grey;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}
.overlay:target {
  visibility: visible;
}
</style>
